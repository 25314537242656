import React, { useEffect, useState, useCallback } from 'react';
import { AdminProps } from '../../Admin';
import './MailingList.css';
import { convertDateToHumanReadable } from '../../Sidebar/Sidebar';
import { FaTrash } from 'react-icons/fa';

interface IMail {
    _id: string;
    email: string;
    createdAt: string;
}

interface MailingListProps extends AdminProps {
    config: {
        apiUrl: string;
        environment: 'development' | 'production';
    };
}

interface PaginatedResponse {
    items: IMail[];
    total: number;
    limit: number;
    totalPages: number;
    page: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    prevPage: number | null;
    nextPage: number | null;
}

const debounce = (func: () => void, delay: number, timeoutId: NodeJS.Timeout = undefined): NodeJS.Timeout => {
    if (timeoutId) clearTimeout(timeoutId);
    return setTimeout(() => {
        func();
    }, delay);
};

export const MailingList: React.FC<MailingListProps> = ({ config }) => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [mailingList, setMailingList] = useState<IMail[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [editorContent, setEditorContent] = useState('');
    const [subject, setSubject] = useState('');
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const [customCss, setCustomCss] = useState('');

    const fetchMailingList = useCallback(async (): Promise<void> => {
        try {
            const response = await fetch(`${config.apiUrl}/mailing-list?page=${page}&filter=${searchTerm}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data: PaginatedResponse = await response.json();
            setMailingList(data.items);
            setTotalPages(data.totalPages);
        } catch (error) {
            if (error['name'] !== 'AbortError') {
                console.error('Error fetching mailing list:', error);
            }
        }
    }, [page, config.apiUrl, searchTerm]);

    useEffect(() => {
        setTimeoutId(debounce(fetchMailingList, 500, timeoutId));
    }, [searchTerm]);

    useEffect(() => {
        fetchMailingList();
    }, [page]);

    const handleNextPage = (): void => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = (): void => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleDelete = async (id: string): Promise<void> => {
        try {
            await fetch(`${config.apiUrl}/mailing-list/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setMailingList(mailingList.filter((mail) => mail._id !== id));
        } catch (error) {
            console.error('Error deleting email:', error);
        }
    };

    const handlePreview = async (): Promise<void> => {
        const html = await fetch(`${config.apiUrl}/mailing-list/html`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        const htmlContent = await html.text();
        setHtmlContent(
            htmlContent
                .replace('{{content}}', editorContent)
                .replace('{{reciever}}', 'reciever@email.hu')
                .replace('/* {{customCss}} */', customCss),
        );
        setIsPreviewOpen(true);
    };

    const handleSend = async (): Promise<void> => {
        if (subject && editorContent) {
            await fetch(`${config.apiUrl}/mailing-list/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    subject,
                    content: editorContent,
                    environment: config.environment,
                    css: customCss,
                }),
            });
        } else {
            alert('Both subject and content are required.');
        }
    };

    return (
        <div className="content content-mailing">
            <div className="text-editor">
                <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Írd be az email tárgyát..."
                    required
                />
                <textarea
                    value={editorContent}
                    onChange={(e) => setEditorContent(e.target.value)}
                    placeholder="<h1>Email tartalom</h1>"
                    required
                />
                <textarea
                    value={customCss}
                    onChange={(e) => setCustomCss(e.target.value)}
                    placeholder="/* Custom CSS styles */"
                    required
                />
                <div className="editor-buttons">
                    <button onClick={handlePreview}>Előnézet</button>
                </div>
            </div>
            <div className="mailing-list-container">
                <h1>Feliratkozott e-mailek</h1>
                <p>Itt kezelhető a levelezőlista</p>
                <input
                    type="text"
                    placeholder="Keresés e-mail alapján"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-bar"
                />
                <table>
                    <thead>
                        <tr>
                            <th>E-mail</th>
                            <th>Feliratkozás dátuma</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {mailingList && mailingList.length > 0 ? (
                            <>
                                {mailingList.map((mail) => (
                                    <tr key={mail._id}>
                                        <td>{mail.email}</td>
                                        <td>{convertDateToHumanReadable(mail.createdAt)}</td>
                                        <td>
                                            <button onClick={() => handleDelete(mail._id)}>
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                {Array.from({ length: 10 - mailingList.length }).map((_, index) => (
                                    <tr key={`placeholder-${index}`}>
                                        <td colSpan={3}>&nbsp;</td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <>
                                <tr>
                                    <td colSpan={3}>Nincs találat a levelezőlistában.</td>
                                </tr>
                                {Array.from({ length: 9 }).map((_, index) => (
                                    <tr key={`placeholder-${index}`}>
                                        <td colSpan={3}>&nbsp;</td>
                                    </tr>
                                ))}
                            </>
                        )}
                    </tbody>
                </table>
                <div className="pagination">
                    <button onClick={handlePrevPage} disabled={page === 1}>
                        Előző
                    </button>
                    <span>
                        Oldal {page} / {totalPages}
                    </span>
                    <button onClick={handleNextPage} disabled={page === totalPages}>
                        Következő
                    </button>
                </div>
            </div>
            {isPreviewOpen && (
                <div className="preview-modal">
                    <div className="preview-content">
                        <button className="mail-button mail-close-button" onClick={() => setIsPreviewOpen(false)}>
                            Bezárás
                        </button>
                        <button className="mail-button mail-send-button" onClick={handleSend}>
                            Küldés
                        </button>
                        <div className="iframe-container">
                            <iframe
                                title="Email Preview"
                                style={{ width: '100%', height: '100%', border: 'none' }}
                                srcDoc={htmlContent}
                            />
                            <div className="iframe-overlay"></div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
