import React, { useState } from 'react';
import './EmailForm.css';

interface EmailFormProps {
    onSubmit: (email: string) => void;
    preEmail?: string;
}

const EmailForm: React.FC<EmailFormProps> = ({ onSubmit, preEmail = '' }) => {
    const [email, setEmail] = useState(preEmail);
    const [honeypot, setHoneypot] = useState('');

    const handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        if (honeypot) {
            // If honeypot is filled, do nothing
            return;
        }
        onSubmit(email);
    };

    return (
        <form className="email-form" onSubmit={handleSubmit}>
            <input
                type="email"
                placeholder="Email cím"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="email"
            />
            <input
                type="email-again"
                placeholder="Email cím újra"
                style={{ display: 'none' }}
                value={honeypot}
                onChange={(e) => setHoneypot(e.target.value)}
                tabIndex={-1}
                autoComplete="off"
            />
            <button type="submit">Feliratkozás</button>
        </form>
    );
};

export default EmailForm;
