export const translations = {
    [1]: {
        display: 'Admin [1]',
        color: {
            rgb: [93, 182, 233],
            hex: '#5DB6E9',
        },
    },
    [2]: {
        display: 'Admin [2]',
        color: {
            rgb: [93, 182, 233],
            hex: '#5DB6E9',
        },
    },
    [3]: {
        display: 'Admin [3]',
        color: {
            rgb: [93, 182, 233],
            hex: '#5DB6E9',
        },
    },
    [4]: {
        display: 'Admin [4]',
        color: {
            rgb: [93, 182, 233],
            hex: '#5DB6E9',
        },
    },
    [5]: {
        display: 'Admin [5]',
        color: {
            rgb: [93, 182, 233],
            hex: '#5DB6E9',
        },
    },
    [6]: {
        display: 'Főadmin',
        color: {
            rgb: [233, 179, 93],
            hex: '#E9B35D',
        },
    },
    [7]: {
        display: 'Manager',
        color: {
            rgb: [198, 93, 233],
            hex: '#B15DE9',
        },
    },
    [8]: {
        display: 'Tulajdonos',
        color: {
            rgb: [233, 93, 93],
            hex: '#E95D5D',
        },
    },
};
