import React, { ReactNode, useEffect, useState } from 'react';
import './Admin.css';
import { Login } from '../Login/Login';
import Sidebar, { sidebarMenus } from './Sidebar/Sidebar';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IUser } from '../../interfaces/user.interface';
import { IPermission } from '../../interfaces/permission.interface';
import { MailingList } from './menus/MailingList/MailingList';
import { isMobile } from 'react-device-detect';

export interface AdminProps {
    config: {
        apiUrl: string;
        environment: 'development' | 'production';
    };
}

export interface IAuthUser {
    user: IUser;
    permission: IPermission;
}

export interface IPermissions {
    mailingList: boolean;
    users: boolean;
    dashboard?: boolean;
}

export const Admin: React.FC<AdminProps> = ({ config }) => {
    const [isLoggedin, setIsLoggedin] = useState(false);
    const [currentPage, setCurrentPage] = useState('Válaszd ki a menüpontot');
    const [isCollapsed, setIsCollapsed] = useState(isMobile);
    const [user, setUser] = useState<IAuthUser>(null);
    const [canView, setCanView] = useState<IPermissions>({ mailingList: false, users: false, dashboard: false });
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        const fetchMailingList = async (): Promise<void> => {
            for (const key in sidebarMenus) {
                try {
                    const response = await fetch(`${config.apiUrl}/${key}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
                    if (response.status === 200) {
                        setCanView({ ...canView, [key]: true });
                    }
                } catch (error) {
                    console.error('Error loading mailing list:', error);
                    throw error;
                }
            }
        };

        fetchMailingList();
    }, []);

    useEffect(() => {
        const checkLogin = async (): Promise<void> => {
            const token = localStorage.getItem('token');

            setIsLoggedin(false);

            if (token && token !== '') {
                try {
                    const response = await fetch(`${config.apiUrl}/auth/admin`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (response.status === 200) {
                        const data = (await response.json()) as IAuthUser;
                        console.log(data);
                        setUser(data);
                        setIsLoggedin(true);
                    }
                } catch (error) {
                    console.error('Error checking login:', error);
                }
            }
        };

        checkLogin();
    }, [config.apiUrl]);

    const handleLogout = (): void => {
        localStorage.removeItem('token');

        setIsLoggedin(false);
    };

    const getCurrentPage = (): ReactNode => {
        switch (currentPage) {
            case 'mailing-list':
                return <MailingList config={config} />;
            default:
                return <></>;
        }
    };

    return (
        <div className="admin">
            {isLoggedin ? (
                <div>
                    <Sidebar
                        config={config}
                        setCurrentPage={setCurrentPage}
                        isCollapsed={isCollapsed}
                        user={user}
                        canView={canView}
                        isMobileMenuOpen={isMobileMenuOpen}
                        setIsMobileMenuOpen={setIsMobileMenuOpen}
                    />
                    <div className={`content ${isCollapsed ? 'content-collapsed' : ''}`}>
                        {!isMobileMenuOpen && (
                            <>
                                <div className={`header ${isCollapsed ? 'header-collapsed' : ''}`}>
                                    <button
                                        className="collapse-button"
                                        onClick={() => {
                                            setIsCollapsed(!isCollapsed);
                                        }}
                                    >
                                        {!isMobile ? isCollapsed ? <FaChevronRight /> : <FaChevronLeft /> : <></>}
                                    </button>
                                    <div className={`title ${isCollapsed ? 'title-collapsed' : ''}`}>
                                        {sidebarMenus[currentPage]?.label}
                                    </div>
                                    <button className="logout-button" onClick={handleLogout}>
                                        Kijelentkezés
                                    </button>
                                </div>
                                {getCurrentPage()}
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <Login config={config} />
            )}
        </div>
    );
};
