import React from 'react';
import './Description.css';

const Description: React.FC = () => {
    return (
        <p className="description">Ha szeretnél értesülni a legújabb fejleményekről, iratkozz fel a hírlevelünkre!</p>
    );
};

export default Description;
