import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import LandingPage from './components/LandingPage/LandingPage';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Router>
        <Routes>
            <Route path="*" element={<LandingPage />} />
        </Routes>
    </Router>,
);

reportWebVitals();
