import React from 'react';
import './LoadingScreen.css';

interface LoadingScreenProps {
    message: string;
    onClose: () => void;
    showCloseButton: boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ message, onClose, showCloseButton }) => {
    return (
        <div className="loading-screen">
            {showCloseButton && (
                <button className="close-button" onClick={onClose}>
                    ×
                </button>
            )}
            <p>{message}</p>
        </div>
    );
};

export default LoadingScreen;
