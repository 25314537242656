import { AdminProps, IAuthUser, IPermissions } from '../Admin';
import { FaBars, FaEnvelope, FaTachometerAlt, FaUsers } from 'react-icons/fa';
import { translations } from './Sidebar.constants';
import { isMobile } from 'react-device-detect';

export const sidebarMenus = {
    dashboard: { label: 'Dashboard', icon: <FaTachometerAlt /> },
    users: { label: 'Felhasználók', icon: <FaUsers /> },
    'mailing-list': { label: 'Levelező lista', icon: <FaEnvelope /> },
};

interface SidebarProps extends AdminProps {
    setCurrentPage: (page: string) => void;
    isCollapsed: boolean;
    user: IAuthUser;
    canView: IPermissions;
    isMobileMenuOpen: boolean;
    setIsMobileMenuOpen: (isOpen: boolean) => void;
}

function convertMinutesToTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return `${hours} óra ${minutes} perc`;
}

export function convertDateToHumanReadable(date: string | Date): string {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');

    return `${year}. ${month}. ${day}. ${dateObj.toLocaleTimeString('hu-HU')}`;
}

const Sidebar: React.FC<SidebarProps> = ({
    config,
    setCurrentPage,
    isCollapsed,
    user,
    canView,
    isMobileMenuOpen,
    setIsMobileMenuOpen,
}) => {
    const collapsed = isMobileMenuOpen ? false : isCollapsed;
    const toggleMobileMenu = (): void => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
        console.log('toggleMobileMenu', !isMobileMenuOpen);
        console.log(collapsed);
    };

    return (
        <>
            {isMobile ? (
                <button className="hamburger-menu" onClick={toggleMobileMenu}>
                    <FaBars />
                </button>
            ) : (
                <></>
            )}
            <div
                className={`sidebar ${collapsed ? 'sidebar-collapsed' : ''} ${isMobile && isMobileMenuOpen ? 'sidebar-mobile-open' : ''}`}
            >
                {(isMobile && isMobileMenuOpen) || !isMobile ? (
                    <>
                        <div className={`logo ${collapsed ? 'logo-collapsed' : ''}`}>
                            <img
                                src={`${config.apiUrl}/assets/logo?size=128`}
                                alt="Logo"
                                width={collapsed ? 34 : 128}
                                height={collapsed ? 34 : 128}
                            />
                        </div>
                        {Object.keys(sidebarMenus).map(
                            (key) =>
                                canView[key] && (
                                    <li
                                        key={key}
                                        className={`menu-item ${collapsed ? 'menu-item-collapsed' : ''}`}
                                        onClick={() => {
                                            setCurrentPage(key);
                                            if (isMobile) {
                                                setIsMobileMenuOpen(false);
                                            }
                                        }}
                                    >
                                        {sidebarMenus[key].icon}
                                        {!collapsed && <span>{sidebarMenus[key].label}</span>}
                                    </li>
                                ),
                        )}
                        {!collapsed && !isMobile && (
                            <div className="user-info">
                                <div className="user-name">
                                    <span style={{ color: translations[user.permission.permission].color.hex }}>
                                        <p>{translations[user.permission.permission].display}</p>
                                    </span>
                                    {user.user.displayName}
                                </div>
                                <p>Időszak kezdete:</p>
                                <p>{convertDateToHumanReadable(user.permission.dutyStart)}</p>
                                <p>Duty idő: {convertMinutesToTime(user.permission.dutyTime)}</p>
                            </div>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default Sidebar;
