import React, { useEffect, useState } from 'react';
import './LandingPage.css';
import ComingSoon from './ComingSoon/ComingSoon';
import Description from './Description/Description';
import EmailForm from './EmailForm/EmailForm';
import LoadingScreen from './LoadingScreen/LoadingScreen';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import UnsubscribePopup from './UnsubscribePopup/UnsubscribePopup';
import Socials from './Socials/Socials';
import { Admin } from '../Admin/Admin';

const LandingPage: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [config, setConfig] = useState(null);
    const [preEmail, setPreEmail] = useState('');
    const [preEmailUnsubscribe, setPreEmailUnsubscribe] = useState('');
    const [showUnsubscribePopup, setShowUnsubscribePopup] = useState(false);
    const [configLoading, setConfigLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const path = location.pathname.split('/');
        path.shift();

        switch (path[0]) {
            case 'admin':
                return;
            case 'mailing-list':
                switch (path[1]) {
                    case 'subscribe':
                        setPreEmail(decodeURIComponent(path[2]));
                        break;
                    case 'unsubscribe':
                        setPreEmailUnsubscribe(decodeURIComponent(path[2] ?? ''));
                        setShowUnsubscribePopup(true);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }

        if (location.pathname !== '/') {
            navigate('/', { replace: true });
        }
    }, [location.pathname, navigate]);

    useEffect(() => {
        const fetchConfig = async (): Promise<void> => {
            try {
                const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
                const configData = await response.json();
                setConfig(configData);
            } catch (error) {
                console.error('Error loading config:', error);
                throw error;
            } finally {
                setConfigLoading(false);
            }
        };

        fetchConfig();
    }, []);

    const handleEmailSubmit = async (email: string): Promise<void> => {
        setLoading(true);
        setLoadingMessage('Kérés folyamatban...');
        setShowCloseButton(false);
        try {
            const response = await fetch(`${config.apiUrl}/mailing-list/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            const result = await response.json();
            if (response.status !== 201) {
                setLoadingMessage('Hiba történt a feliratkozás közben.');
                throw new Error('Failed to submit email.');
            }
            if (!response.ok) {
                throw new Error(result.message);
            }
            setLoadingMessage('Sikeresen feliratkoztál!');
        } catch (error) {
            setLoadingMessage('Hiba történt a feliratkozás közben.');
            console.error('Error submitting email:', error);
        } finally {
            setShowCloseButton(true);
        }
    };

    const handleClose = (): void => {
        setLoading(false);
    };

    const handleCloseUnsubscribePopup = (response?: Promise<Response> | false): void => {
        setShowUnsubscribePopup(false);
        navigate('/', { replace: true });
        if (response && typeof response.then === 'function') {
            setLoading(true);
            setLoadingMessage('Leiratkozás folyamatban...');
            setShowCloseButton(false);

            response
                .then((res) => {
                    if (res.status !== 201) {
                        setLoadingMessage('Hiba történt a leiratkozás közben.');
                        throw new Error('Failed to unsubscribe email.');
                    }
                    setLoadingMessage('Sikeresen leiratkoztál!');
                })
                .catch((error) => {
                    setLoadingMessage('Hiba történt a leiratkozás közben.');
                    console.error('Error unsubscribing email:', error);
                })
                .finally(() => {
                    setShowCloseButton(true);
                });
        }
    };

    if (configLoading) {
        return (
            <LoadingScreen
                message="Loading configuration..."
                onClose={() => {
                    return;
                }}
                showCloseButton={false}
            />
        );
    }

    return (
        <Routes>
            <Route
                path="/admin/*"
                element={
                    <>
                        <Admin config={config} />
                    </>
                }
            />
            <Route
                path="/"
                element={
                    <div className="landing-page">
                        {loading && (
                            <div className="loading-overlay">
                                <LoadingScreen
                                    message={loadingMessage}
                                    onClose={handleClose}
                                    showCloseButton={showCloseButton}
                                />
                            </div>
                        )}
                        <div className="logo-container">
                            <img src={`${config.apiUrl}/assets/logo?size=256`} alt="Logo" className="logo" />
                            <div className="divider"></div>
                            <img src={`${config.apiUrl}/assets/fivem?size=256`} alt="Logo" className="logo" />
                        </div>
                        <ComingSoon />
                        <Description />
                        <EmailForm onSubmit={handleEmailSubmit} preEmail={preEmail} />
                        {showUnsubscribePopup && (
                            <div className="loading-overlay">
                                <UnsubscribePopup
                                    preEmail={preEmailUnsubscribe}
                                    onClose={handleCloseUnsubscribePopup}
                                    showCloseButton={true}
                                    config={config}
                                />
                            </div>
                        )}
                        <Socials config={config} />
                    </div>
                }
            />
        </Routes>
    );
};

export default LandingPage;
