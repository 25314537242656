import { useState } from 'react';
import './Login.css';

interface LoginProps {
    config: {
        apiUrl: string;
    };
}

export const Login: React.FC<LoginProps> = ({ config }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault();
        const response = await fetch(`${config.apiUrl}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('token', data.token);
            console.log(data.token);
            window.location.reload();
        } else {
            console.error('Login failed:', response.statusText);
        }
    };

    return (
        <form className="login" onSubmit={handleLogin}>
            <div className="login-header">
                <img src={`${config.apiUrl}/assets/logo?size=24`} alt="Logo" /> <p>Bejelentkezés</p>
            </div>
            <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Felhasználónév"
                required
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Jelszó"
                required
            />
            <button type="submit">Bejelentkezés</button>
        </form>
    );
};
