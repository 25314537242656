import React from 'react';
import { FaDiscord, FaFacebook } from 'react-icons/fa';
import './Socials.css';

interface SocialsProps {
    config: {
        discordUrl: string;
        facebookUrl: string;
    };
}

const Socials: React.FC<SocialsProps> = ({ config }) => {
    return (
        <div className="socials">
            {config.discordUrl ? (
                <a href={config.discordUrl} target="_blank" rel="noopener noreferrer">
                    <FaDiscord size={32} />
                </a>
            ) : (
                <></>
            )}
            {config.facebookUrl ? (
                <a href={config.facebookUrl} target="_blank" rel="noopener noreferrer">
                    <FaFacebook size={32} />
                </a>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Socials;
