import React, { useState } from 'react';
import './UnsubscribePopup.css';

interface UnsubscribePopupProps {
    preEmail: string;
    onClose: (response?: Promise<Response> | false) => void;
    showCloseButton: boolean;
    config: {
        apiUrl: string;
    };
}

const UnsubscribePopup: React.FC<UnsubscribePopupProps> = ({ preEmail, onClose, showCloseButton, config }) => {
    const [email, setEmail] = useState(preEmail);

    const handleUnsubscribe = (): void => {
        const response = fetch(`${config.apiUrl}/mailing-list/unsubscribe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        onClose(response);
    };

    return (
        <div className="unsubscribe-popup">
            {showCloseButton && (
                <button className="close-button" onClick={() => onClose(false)}>
                    ×
                </button>
            )}
            <p>
                Leiratkozáshoz add meg az email címed,<br></br>amivel feliratkoztál a hírlevelünkre.
            </p>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email cím"
                required
                autoComplete="off"
            />
            <button className="unsubscribe-button" onClick={handleUnsubscribe}>
                Leiratkozás
            </button>
        </div>
    );
};

export default UnsubscribePopup;
